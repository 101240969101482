<template>
  <div>
    <div v-if="!loading.results && invoice._id" :key="invoice._id">
      <!-- TABS -->
      <ul class="nav nav-tabs nav-tabs-alt" role="tablist">
        <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
          <a class="nav-link" :class="{ active: activeTab === tab.value }" active-class="active" href="#" @click.prevent="activeTab = tab.value">
            <i class="mr-1" :class="tab.icon"></i> {{ tab.label }}
          </a>
        </li>
      </ul>
      <!-- TABS -->

      <!-- DETAILS TAB -->
      <div v-if="activeTab === 'details'">
        <div class="row no-gutters pb-2 mb-3 mt-3 align-items-end">
          <div class="col">
            <h3 class="mb-0">Invoice Details</h3>
          </div>
          <div class="text-right">
            <button v-if="!editMode" class="btn btn-primary btn-sm" @click="modals.edit = true"><i class="fa fa-pencil mr-2"></i>Edit</button>
            <button v-else class="btn btn-link text-danger fa-lg" @click="onClickCancel"><i class="fa fa-times danger"></i></button>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <PropertyListItem class="d-flex align-items-center mb-2" title="Simulated Data" :border="!!editMode">
              <FormItem
                v-if="editMode"
                id="simulated"
                v-model="form.simulated"
                type="select"
                :options="[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true }
                ]"
              />
              <span v-else>
                {{ form.simulated ? 'Yes' : 'No' }}
              </span>
            </PropertyListItem>
            <PropertyListItem class="d-flex align-items-center mb-2" title="Supplier" :border="!!editMode">
              <FormItem
                v-if="editMode"
                id="supplierId"
                v-model="form.supplierId"
                :options="sortedSuppliers.map(s => ({ label: s.name, value: s._id }))"
                label="Supplier Name"
                type="select"
                :config="{ allowSearch: true }"
              />
              <span v-else>
                {{ sortedSuppliers.find(s => s._id === form.supplierId)?.name || 'N/A' }}
              </span>
            </PropertyListItem>
            <PropertyListItem
              v-for="value in invoiceValues.slice(0, Math.floor(invoiceValues.length / 2))"
              :key="value.key"
              class="d-flex align-items-center mb-2"
              :title="value.friendlyKey"
              :border="!!editMode"
            >
              <span v-if="editMode">
                <FormItem :id="value.key" :type="value.formType" :value="value.friendlyValue" @input="input => onChangeValue(input, value.key)" />
              </span>
              <span v-else>
                {{ value.friendlyValue | round(2) }}
              </span>
            </PropertyListItem>
          </div>
          <div class="col-md-6">
            <PropertyListItem
              v-for="value in invoiceValues.slice(Math.floor(invoiceValues.length / 2), invoiceValues.length)"
              :key="value.key"
              class="d-flex align-items-center mb-2"
              :title="value.friendlyKey"
              :border="!!editMode"
            >
              <span v-if="editMode">
                <FormItem :id="value.key" :type="value.formType" :value="value.friendlyValue" @input="input => onChangeValue(input, value.key)" />
              </span>
              <span v-else>
                {{ value.friendlyValue | round(2) }}
              </span>
            </PropertyListItem>
            <PropertyListItem class="d-flex align-items-center mb-2" title="Validation Date" :border="!!editMode">
              <FormItem v-if="editMode && form.confirmedAt" id="confirmedAt" v-model="form.confirmedAt" type="text" :disabled="true" />
              <span v-else-if="form.confirmedAt">
                {{ form.confirmedAt }}
              </span>
              <span v-else> N/A </span>
            </PropertyListItem>
          </div>
        </div>

        <!-- WASTE -->
        <div v-if="invoice.type === 'waste'">
          <SectionTitle>Consumption</SectionTitle>

          <div v-if="loadingActionConsumption.list">
            <Spinner />
          </div>

          <div v-else class="row">
            <div class="col-xl-8">
              <table v-if="editMode" class="table table-borderless mb-4">
                <tbody>
                  <tr v-for="(category, idx) in wasteCategories" :key="category.name">
                    <td class="font-w600">{{ category.name }}</td>
                    <td>
                      <div class="input-group">
                        <FormItem :id="`consumption-value-${idx}`" v-model="wasteConsumption[category.name].value" class="col-xl-3" placeholder=" " />
                        <FormItem
                          :id="`consumption-unit-${idx}`"
                          v-model="wasteConsumption[category.name].unit"
                          class="col-xl-3"
                          type="select"
                          alt
                          :placeholder="`Select a unit`"
                          :options="[
                            { label: 'kg', value: 'kg' },
                            { label: 'tonnes', value: 'tonnes' }
                          ]"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-else>
                <PropertyListItem
                  v-for="category in Object.keys(wasteConsumption).filter(category => wasteConsumption[category].value > 0)"
                  :key="category"
                  class="d-flex align-items-center mb-2"
                  :title="category"
                >
                  {{ wasteConsumption[category].value | numberFormat(2) }} {{ wasteConsumption[category].unit }}
                </PropertyListItem>
              </div>
            </div>
          </div>
        </div>
        <!-- WASTE -->

        <!-- RATES -->
        <div class="mt-3">
          <div class="row no-gutters pb-2 mb-3 align-items-center">
            <h3 class="mb-0 pb-0">Rates</h3>
            <div class="text-right ml-auto">
              <button v-if="editMode" class="btn btn-primary ml-3" @click="onClickAddRate">Add Rate</button>
            </div>
          </div>
          <p v-if="editMode">All the rates related to this invoice. Changing the consumption or cost of a rate will update the totals above.</p>
          <div v-if="!form.rates.length" class="alert alert-warning">This invoice currently has no rates listed</div>
          <div v-else>
            <div v-for="category in rateTypes" :key="category.value">
              <div v-if="form.rates.filter(rate => rate.type === category.value)?.length">
                <h5>{{ category.label }}</h5>
                <InvoiceResultsRateTable
                  :rates="form.rates.filter(rate => rate.type === category.value)"
                  :edit-mode="editMode"
                  :form="form.rates"
                  :register-ids="filteredRegisterIds(account.registerIds)"
                  :unit-options="unitOptions"
                  @change="onChangeRate"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- RATES -->

        <div v-if="editMode || form.s3Key">
          <SectionTitle>Invoice File</SectionTitle>
          <div class="row push">
            <div class="d-flex flex-column col-auto">
              <FileUpload
                v-model="form.file"
                :loading="fileLoading"
                :existing-file-name="form.fileName"
                :read-only="!editMode"
                :error="validationErrors.upload"
                @reset="onClearInvoiceFile"
              />
            </div>
          </div>
        </div>

        <div v-if="editMode">
          <button class="btn btn-primary mr-3" :class="{ disabled: isSavingChanges }" @click="onClickConfirmChanges">
            <span v-if="isSavingChanges"><i class="fa fa-circle-notch fa-spin mr-1"></i> Saving...</span>
            <span v-else>Confirm Changes</span>
          </button>
          <button class="btn btn-alt-warning" :class="{ disabled: isSavingChanges }" @click="onClickCancel">Undo Changes</button>
        </div>
      </div>
      <!-- DETAILS TAB -->

      <!-- VALIDATION TAB -->
      <div v-else class="mt-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h3 class="mb-2">
            Validation
            <span v-if="form.validation" class="font-size-lg">
              <span class="badge badge-danger mr-2">{{ form.validation.filter(result => result.type === 'danger').length }} issues</span>
              <span class="badge badge-warning">{{ form.validation.filter(result => result.type === 'warning').length }} warnings</span>
            </span>
          </h3>
          <button class="btn btn-alt-info mr-3" @click="onClickRevalidate">
            <span><i class="fa fa-fw fa-redo"></i> Refresh</span>
          </button>
        </div>

        <div>
          <div>
            <div class="row">
              <div class="col-6">
                <p class="mb-2">
                  This invoice has been validated against a set of rules. Checks are made against related invoices, accounts and contracts. Anything
                  that may be an issue or problem will be highlighted. For example, if the invoice dates overlap with another invoice on the same
                  account.
                </p>

                <p class="mb-2">Click the refresh button to re-validate.</p>
                <p>
                  <strong>Note:</strong> If you have made changes on the details tab, then you'll need to confirm those changes before re-validating
                  to see the updated results.
                </p>
              </div>
              <div class="col">
                <Spinner v-if="loadingAction.validate" />
                <div v-else-if="!form.validation" class="alert alert-warning">
                  This invoice currently has no validation. Please click the refresh button to re-validate.
                </div>
                <div v-for="(item, index) in validationAlerts" v-else :key="index">
                  <div class="alert" :class="`alert-${item.type}`" role="alert">
                    <p class="mb-0">
                      <i class="fa fa-fw mr-1" :class="item.icon" />
                      <strong v-if="item.title">{{ item.title }}</strong>
                      <span v-else>{{ item.message }}</span>
                    </p>
                    <div v-if="item.subTitle">
                      <strong>{{ item.subTitle }}</strong> {{ item.message }}
                      <div>
                        <span class="badge" :class="`badge-${item.type}`">{{ item.rateType }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- VALIDATION TAB -->
    </div>
    <div v-else-if="loading.results" class="d-flex justify-content-center py-5">
      <div class="spinner-border spinner-lg text-secondary my-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <ConfirmModal
      :open="modals.edit"
      title="Edit Invoice"
      text="Please be aware that editing an invoice can affect reports and other data throughout Etainabl. If you have downloaded any reports which include this invoice/account/asset's data, you may need to re-download or re-export the report.<br/><strong>Note: Changes will not be re-validated against contracts and other invoices</strong>"
      @close="modals.edit = false"
      @submit="onEdit"
    />
    <ConfirmModal
      v-model="modals.addRate"
      :open="!!modals.addRate"
      title="Add Rate"
      text="Please enter/select a rate name"
      lg-size
      @close="modals.addRate = false"
      @submit="onConfirmRateModal"
    >
      <AddRateForm v-model="modals.addRate" :register-ids="filteredRegisterIds(account.registerIds)" :unit-options="unitOptions" />
    </ConfirmModal>
  </div>
</template>
<script>
import moment from 'moment';
import slugify from 'slugify';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { calculateTotals, invoiceTotalKeys, rateTypes } from '../lib/invoice';
import { prepareInvoiceConsumptionForDB } from '../lib/consumption';

import AddRateForm from '@/components/AddRateForm';
import ConfirmModal from './ConfirmModal';
import FileUpload from './FileUpload';
import FormItem from './FormItem';
import InvoiceResultsRateTable from './InvoiceResultsRateTable';
import PropertyListItem from '@/components/base/PropertyListItem';
import SectionTitle from '@/components/base/SectionTitle';
import Spinner from '@/components/Spinner';

const rateTitles = {
  unitRate: 'Unit Rate',
  consumption: 'Consumption',
  startRead: 'Start Read',
  endRead: 'End Read',
  startDate: 'Start Date',
  endDate: 'End Date',
  cost: 'Cost'
};

const iconTypes = {
  warning: 'fa-exclamation-triangle',
  danger: 'danger-info fa-exclamation-triangle',
  success: 'fa-check-circle text-success',
  info: 'fa-info-circle text-info'
};

const formTypeMap = {
  price: 'number',
  date: 'datePicker',
  string: 'text'
};

export default {
  name: 'InvoiceResults',
  components: {
    AddRateForm,
    ConfirmModal,
    FileUpload,
    FormItem,
    InvoiceResultsRateTable,
    PropertyListItem,
    SectionTitle,
    Spinner
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      modals: {
        edit: false,
        addRate: false
      },
      activeTab: 'details',
      fileLoading: false,
      editMode: false,
      form: {
        simulated: false,
        values: {},
        rates: [],
        fileName: '',
        confirmedAt: null,
        s3Key: null,
        stampedS3Key: null,
        file: null,
        validation: null,
        supplierId: null
      },
      wasteConsumption: {},
      isSavingChanges: false,
      rateTypes: [...rateTypes]
    };
  },
  computed: {
    ...mapGetters({
      account: 'asset/selectedAccount',
      suppliers: 'supplier/suppliers',
      invoiceKeys: 'invoiceTemplate/invoiceKeys',
      validationErrors: 'invoice/validationErrors',
      wasteCategories: 'account/wasteCategories',
      consumptions: 'consumption/consumption',
      loadingActionConsumption: 'consumption/loadingAction',
      loadingAction: 'invoice/loadingAction',
      units: 'util/units'
    }),
    unitOptions() {
      return this.units.filter(u => u.types.includes(this.account.type)).map(u => ({ label: u.name, value: u.value }));
    },
    invoiceValues() {
      return this.populatedInvoiceValues();
    },
    sortedSuppliers() {
      let suppliers = [...this.suppliers];

      suppliers.sort((a, b) => a?.name?.localeCompare(b.name));

      return suppliers;
    },
    validationAlerts() {
      if (!this.form.validation) return [];

      return this.form.validation.map(validation => {
        const data = {
          ...validation,
          icon: iconTypes[validation.type]
        };

        const rate = this.invoice.rates.find(rate => validation.key?.includes(rate.id));
        if (rate) {
          const rateSubTitle = rateTitles[validation.subKey];
          return {
            ...data,
            title: `${rate.rateName ? rate.rateName : 'Untitled Rate'}`,
            subTitle: `${rateSubTitle}: ${rate[validation.subKey] || ''} - `,
            rateType: rateTypes.find(type => type.value === rate.type).label
          };
        }

        return data;
      });
    },
    tabs() {
      return [
        { label: 'Details', icon: 'fa-solid fa-list-ul', value: 'details' },
        { label: 'Validation', icon: 'fa fa-file-invoice', value: 'validation' }
      ];
    }
  },
  async created() {
    if (this.invoiceKeys.length === 0) {
      this.getInvoiceKeys();
    }
    this.listSuppliers();
    this.refreshFormData(this.invoice);
    this.refreshWaste();
  },
  methods: {
    ...mapActions({
      listSuppliers: 'supplier/list',
      download: 'invoice/download',
      updateInvoice: 'invoice/manualUpdate',
      getInvoiceKeys: 'invoiceTemplate/getInvoiceKeys',
      upload: 'invoice/manualUpload',
      getWasteCategories: 'account/getWasteCategories',
      listConsumption: 'consumption/list',
      createBulkConsumption: 'consumption/createBulk',
      removeConsumption: 'consumption/remove',
      convertManualValues: 'invoice/convertManualValues',
      updateValidation: 'invoice/updateValidation',
      listUnits: 'util/listUnits'
    }),
    ...mapMutations({
      clearValidationErrors: 'invoice/CLEAR_VALIDATION_ERRORS'
    }),
    filteredRegisterIds(registerIds) {
      return [...new Set([...registerIds, '1'])].map(id => ({ value: id, label: id }));
    },
    async onClickRevalidate() {
      this.form.validation = await this.updateValidation({ id: this.invoice._id });
    },
    refreshFormData(invoice) {
      if (invoice.s3Key) {
        this.form.s3Key = invoice.s3Key;
        this.form.fileName = invoice.fileName;
        this.form.stampedS3Key = invoice.stampedS3Key;
        this.form.confirmedAt = moment(invoice.confirmedAt).format('DD/MM/YYYY');
      }

      this.form.values = invoice.values || {};
      this.form.rates = invoice.rates || [];
      this.form.simulated = invoice.simulated;
      this.form.validation = invoice.validation;
      this.form.supplierId = invoice.supplierId;
    },
    async refreshWaste() {
      if (this.invoice.type === 'waste') {
        await Promise.all([this.getWasteCategories(), this.listConsumption({ data: { params: { invoiceId: this.invoice._id } } })]);

        this.wasteCategories.forEach(category => {
          const relevantConsumptions = this.consumptions.filter(consumption => consumption.category === category.name);

          this.$set(this.wasteConsumption, category.name, {
            value: relevantConsumptions.length > 0 ? relevantConsumptions.reduce((s, v) => s + v.value, 0) : 0,
            unit: relevantConsumptions.length > 0 ? relevantConsumptions[0].unit : 'kg'
          });
        });
      }
    },
    recalculateTotals() {
      const totals = calculateTotals(this.form.rates, this.form.values);

      invoiceTotalKeys.forEach(key => {
        totals[`friendly${key.charAt(0).toUpperCase() + key.slice(1)}`] = Number(totals[key]).toFixed(2);
      });

      Object.assign(this.form.values, totals);
    },
    populatedInvoiceValues() {
      if (!this.form.values) return [];

      return this.invoiceKeys
        .filter(invoiceKey => invoiceKey.types.includes(this.invoice.type))
        .filter(invoiceKey => !this.$auth.settings.hideCostData || invoiceKey.valueType !== 'price')
        .map(invoiceKey => {
          // Matching invoice value
          const invoiceValue = this.form.values[invoiceKey.key];
          const friendlyInvoiceValue = this.form.values[`friendly${invoiceKey.key.charAt(0).toUpperCase()}${invoiceKey.key.slice(1)}`];

          return {
            key: invoiceKey.key,
            friendlyKey: invoiceKey.friendly,
            value: invoiceValue,
            formType: invoiceKey ? formTypeMap[invoiceKey.valueType] : 'string',
            valueType: invoiceKey ? invoiceKey.valueType : 'N/A',
            friendlyValue: friendlyInvoiceValue || invoiceValue
          };
        });
    },
    onClickAddRate() {
      const newRate = {
        rateName: 'New Rate',
        type: 'unitRate',
        consumption: 0,
        unitRate: 0,
        cost: 0,
        startRead: null,
        startReadType: null,
        endRead: null,
        endReadType: null,
        startDate: this.form.values?.startDate || null,
        endDate: this.form.values?.endDate || null,
        startReadUnit: this.unitOptions[0]?.value,
        endReadUnit: this.unitOptions[0]?.value
      };

      this.modals.addRate = newRate;
    },
    async onConfirmRateModal() {
      const convertedValues = await this.convertManualValues({
        data: {
          rates: [this.modals.addRate, ...this.form.rates],
          values: this.form.values,
          invoiceKeys: this.invoiceKeys
        }
      });

      this.form.rates = convertedValues.rates;

      this.$toasted.success('Rate added');

      this.modals.addRate = false;
    },
    async onClickDownloadInvoice() {
      try {
        const startDate = moment(this.invoice.values.startDate).format('DD-MM-YYYY');
        const endDate = moment(this.invoice.values.endDate).format('DD-MM-YYYY');
        const fileName = `${startDate}-${endDate}_${slugify(this.invoice.supplier.name.toLowerCase())}_${this.invoice.type}.pdf`;

        const downloadUrls = await this.download({ id: this.invoice.id, name: fileName });

        const link = document.createElement('a');
        link.target = '_blank';
        link.href = downloadUrls.url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        this.$toasted.error(e.errorMsg || 'Could not download file', { position: 'bottom-center', duration: 3000 });
      }
    },
    onEdit() {
      this.modals.edit = false;
      this.form.values = { ...this.invoice.values };
      this.form.rates = [...this.invoice.rates];
      this.editMode = true;
    },
    onChangeValue(value, key) {
      this.$set(this.form.values, key, value);
    },
    onChangeRate(updatedRates) {
      this.form.rates = updatedRates;
      this.recalculateTotals();
    },
    onClickCancel() {
      this.refreshFormData(this.invoice);

      this.form.source = 'supplier';
      this.editMode = false;
    },
    async onClickConfirmChanges() {
      try {
        // Stop saving if there are no changes
        if (Object.keys(this.form.values).length === 0) return false;

        this.isSavingChanges = true;

        // Upload file if it has been selected
        if (this.form.file) {
          this.fileLoading = true;

          const { s3Key } = await this.upload({
            data: this.form.file.file
          });

          if (this.validationErrors.upload) {
            throw new Error('Upload failed');
          }

          this.form.s3Key = s3Key;
          this.form.fileName = this.form.file.fileName;
        }

        if (this.invoice.type === 'waste') {
          // Apportion waste consumption into months (if start and end date cover multiple months)

          const consumptionBase = {
            source: 'invoice',
            accountId: this.invoice.accountId,
            entityId: this.invoice.entityId,
            companyId: this.invoice.companyId,
            invoiceId: this.invoice._id
          };

          const consumptionData = prepareInvoiceConsumptionForDB(
            consumptionBase,
            this.wasteConsumption,
            this.form.values.startDate,
            this.form.values.endDate
          );

          const filteredConsumptionData = consumptionData.filter(c => c.value > 0);
          const oldConsumptionData = [...this.consumptions];

          if (filteredConsumptionData.length > 0) {
            const newConsumptions = await this.createBulkConsumption({ data: filteredConsumptionData });

            if (newConsumptions.length !== filteredConsumptionData.length && newConsumptions.every(c => c._id)) {
              this.$toasted.error('There was an issue updating consumption values.');
              this.isSavingChanges = false;
              return;
            }
          }

          // Delete the old consumption values
          await Promise.all(oldConsumptionData.map(c => this.removeConsumption({ id: c._id })));

          await this.refreshWaste();
        }

        const updatedInvoice = await this.updateInvoice({
          id: this.invoice._id,
          data: {
            ...this.form,
            file: null,
            confirmedAt: undefined
          }
        });

        if (updatedInvoice._id) {
          this.$toasted.success('Updated invoice successfully');
          this.editMode = false;
          this.fileLoading = false;
          this.refreshFormData(updatedInvoice);
          this.$emit('change', updatedInvoice);
        } else {
          throw new Error();
        }
      } catch (e) {
        this.isSavingChanges = false;
        this.fileLoading = false;
        this.$toasted.error(e.message || 'There was a problem updating this invoice');
        throw e;
      }

      this.isSavingChanges = false;
    },
    onClearInvoiceFile() {
      this.form.file = null;
      this.form.s3Key = null;
      this.form.fileName = null;
      this.clearValidationErrors('upload');
    }
  }
};
</script>
<style lang="scss" scoped>
.is-changed {
  color: #da4c4c !important;
  text-decoration: line-through;
  font-weight: 600;
}
.is-calculated {
  color: #3ca761 !important;
  font-weight: 600;
}
</style>
